export const RISK_RATINGS_TEXTS = {
  NAME: "The name of the risk rating. The length of the name must be shorter than 100 characters.",
  EXECUTIVE_SUMMARY:
    "Enter free-form text summarizing the risk to this asset for the relevant hazard",
  ASSESSMENT_TYPES:
    "Select whether the assessment is based on current risk (default) or a future scenario (e.g. climate change)",
  CONSEQUENCE_TYPES: "Select the consequence types that are assessed",
  ASSET: "Consequences associated with impact to the asset itself.",
  SITE_ACCESS: "Consequences associated with impacts to access to the asset",
  UTILITIES:
    "Consequences associated with impacts to the asset's utility supply",
  FURTHER_ANALYSIS_NEEDED:
    "Check this box if it is judged that further analysis is required to confirm the risk rating for this hazard",
  RISK_APPETITE:
    "Select the risk appetite associated with this asset, hazard, and consequence. Select 'Neutral' by default, unless there is reason to believe otherwise",
  LIKELIHOOD:
    "Select the range of return periods associated with the impacting event that controls the risk. If hazard intensity is known at only one return period, select the range containing that return period",
  CONSEQUENCE:
    "Select the range of consequence levels associated with the impacting event that controls the risk. If only one level of hazard intensity is known, select the consequence level associated with that hazard intensity",
  CONFIDENCE:
    "Select the confidence of the risk assessment on a scale from 1 to 4. This should correspond to the 'Class' of risk assessment, with Class = 1 representing lowest effort and Class = 4 representing highest effort",
  RISK_RATING:
    "Qualitative risk rating. Typically this is calculated automatically based on likelihood and consequence. If overriding to assign the rating directly, use best judgement to be consistent with any other risk ratings.",
  TIME_HORIZON: "Time horizon associated to an RCP scenario.",
  RCP_SCENARIO:
    "Representative Concentration Pathway (RCP) is an international standard for defining the greenhouse gas concentration trajectory. ",
};

export const COMPARISON_GRAPH_TEXTS = {
  CONSEQUENCE:
    "Select the consequence type to display the corresponding risk ratings in the chart",
  HAZARD: "Select the hazard to be highlighted in the chart",
  SHOW_ONLY_HIGHLIGHTED:
    "Select this toggle to remove all non-highlighted hazards",
  DIRECTION_ASSET:
    "Select the orientation of the bars in the plot. \nℹ️ In the horizontal orientation the asset name is shown where in the vertical orientation we, if applicable, show the asset nickname.",
  DIRECTION_GROUP: "Select the orientation of the bars in the plot.",
  BAR_COLORS:
    "The bar length and bar color both represent the same information: risk as measured by the long-term average annual loss. \n - For downtime, this can be described as average annual downtime. \nBars with the same color will also have the same length. \nRisk measures are consistent across hazards for useful comparisons.",
  SORT_BY: "Select a value in which you'd like to order the bars in the plot.",
};

export const CONSEQUENCE_INFO_TEXT: { [key: string]: string } = {
  "Health & Wellness":
    "Impacts to cognitive and/or physiological well-being of occupants of the asset.",
  "Repair Costs":
    "Financial losses stemming primarily from repairing the damaged asset, reconstructing the damaged asset, and/or revenue loss.",
  "Inventory Loss":
    "Financial losses stemming from destruction of inventory within the asset.",
  Downtime: "Operational disruption to the asset.",
  "Individual Life Safety":
    "Likelihood of fatality for any occupant of the asset.",
  "Aggregate Life Safety": "Expected fatalities for occupants of the asset.",
  Roads: "Disruption of access to the asset via roads.",
  Bridges: "Disruption of access to the asset via bridges.",
  Rail: "Disruption of access to the asset via railways.",
  Power: "Disruption of power utility for the asset.",
  Water: "Disruption of water utility for the asset.",
  Waste: "Disruption of wastewater utility for the asset.",
  Telecom: "Disruption of telecommunications for the asset.",
  Fiber: "Disruption of fiber connectivity for the asset.",
  Damage:
    "Likelihood of physical damage that could cause fatality (in any amount) for any occupant of the asset.",
  "Severe Weather Warning":
    "Likelihood of a local jurisdiction issuing a warning that severe weather is occurring in an area or is expected to develop or move into an area that could impact an asset.",
  "Hazard Rating":
    "Description of the hazard intensity at the asset location, with no regard for asset vulnerability.",
};

export const COST_BENEFIT_GRAPH_TEXT = {
  PROVIDE_DATA:
    "Please provide 'Annualized losses' and/or 'Annualized avoided losses' and/or 'Cost of implementation' to view the graph.",
};

export const MAP_TEXT = {
  PROVIDE_DATA:
    " Please provide latitude and longitude values to the asset(s) to view the map.",
  NEW_ASSET:
    "Please provide a valid address or a latitude and longitude to view the map.",
};

export const HAPI_PAID_WARNING =
  "If you’ve already made the request you won’t be billed twice. Selecting different asset coordinates, time horizon, or RCP scenario will result in another bill.";

export const ARCHETYPE_FORM = {
  DATA_SECTION:
    "Use this section to define information about the archetype that will be uploaded. This information will be used to filter the archetypes during selection.",
  CONFIGURATION:
    "Configure which consequences, intensity measures and percentiles that the loss curves represent. This will determine the number of loss curves that need to be created.",
  DEFINE_CURVES:
    "Create and edit the loss curve values for each of the configured fields.",
  LOSS_CURVES: "Use this section to upload loss curves to the archetype.",
};
export const ARCHETYPE = {
  DELETE_TEXT: "Are you sure you wish to delete this archetype?",
};
export const ASSET_FORM = {
  LOCATION:
    "Iris uses the latitude and longitude of your asset to detect the parcel and building floor area and ensures accuracy of the hazard information used for risk analysis.",
  GENERAL_INFORMATION:
    "Iris uses the ownership and status information to organize your portfolio effectively.",
  DETAILED_INFORMATION:
    "The population, replacement value and revenue values are used for more advanced modeling.",
  ADVANCED: "These parameters are used for Class 3 modeling.",
  FLOOR_INFORMATION:
    "Total Floor Area and Total Population is automatically calculated from the floor information, only floors that has the population set will be included in the calculation.",
};
