import React from "react";
import type { ReactElement } from "react";

import { Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import type { Breakpoint } from "@mui/material";
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from "@mui/material";

interface Options {
  buttonVariant?: "text" | "outlined" | "contained";
  showCancel?: boolean;
  showAction?: boolean;
  maxWidth?: Breakpoint;
  maxHeight?: number;
  disableActionButton?: boolean;
  closeOnClickOutside?: boolean;
}

interface Props {
  openDialog: boolean;
  closeDialog: (e?: any) => void;
  dialogTitle: string | ReactElement;
  loading?: boolean;
  buttonTitle?: string;
  handleAction?: (e?: any) => void;
  content?: string;
  children?: any;
  dataTest?: string;
  options?: Options;
}

export const Dialog = (props: Props) => {
  const { openDialog, closeDialog, handleAction, loading } = props;
  const { buttonTitle, dialogTitle } = props;
  const { content, children, dataTest, options } = props;
  const { maxWidth = "xs", maxHeight = "450" } = options ?? {};
  const { buttonVariant = "text" } = options ?? {};
  const { showCancel = true, showAction = true } = options ?? {};
  const { disableActionButton, closeOnClickOutside = true } = options ?? {};

  return (
    <MuiDialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: maxHeight } }}
      maxWidth={maxWidth}
      open={openDialog}
      onClose={closeOnClickOutside ? closeDialog : undefined}
      data-test="dialog-container"
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={closeDialog}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <Close />
      </IconButton>
      {children && <DialogContent dividers>{children}</DialogContent>}
      {content && (
        <Typography variant="body1">
          <DialogContent dividers>{content}</DialogContent>
        </Typography>
      )}
      <DialogActions sx={{ p: 2 }}>
        {showCancel ? (
          <Button
            disabled={loading}
            onClick={closeDialog}
            data-test="dialog-cancel-button"
          >
            Cancel
          </Button>
        ) : null}

        {showAction ? (
          <LoadingButton
            type="submit"
            loading={loading}
            size="medium"
            onClick={handleAction}
            data-test={dataTest ?? "dialog-submit-button"}
            disabled={disableActionButton}
            variant={buttonVariant}
          >
            {buttonTitle}
          </LoadingButton>
        ) : null}
      </DialogActions>
    </MuiDialog>
  );
};
